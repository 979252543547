<template>
  <v-container>
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="$t('pageDescriptions.leads')"
    />

    <DataTable
      :headers="headers"
      :items="data"
      :preloader="fetchinData"
      :edit="false"
      :watch="true"
      :trash="false"
      :copy="false"
      :leads="false"
      :preview="false"
      :stats="false"
      @tableEvent="emittedTableEvent"
    ></DataTable>

    <v-container class="mt-6">
      <v-row no-gutters>
        <v-col cols="12">
          <div class="d-flex align-end flex-column">
            <v-row>
              <v-btn large color="green" dark @click="dialogManualLead = true">
                {{ $t("createLeadManually") }}
                <v-icon right dark>mdi-plus</v-icon>
              </v-btn>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="dialog"
      max-width="1500px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="dialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <v-toolbar-title>Lead Details</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <!-- Lead der "alten" Version  -->
          <LeadDetailOld
            v-if="leadData.version === 1"
            ref="lead_detail"
            :id="leadId"
            :leadData="leadData"
            :key="componentKey"
          ></LeadDetailOld>

          <!-- Lead der "neuen" Version  -->

          <template v-else-if="leadData.version === 2">
            <template
              v-if="
                leadData.campaign.hasMitfitMember ||
                leadData.campaign.hasMitfitShop
              "
            >
              <LeadDetailView
                ref="lead_detail"
                :leadId="leadId"
                :key="componentKey"
                :type="leadData.campaign.hasMitfitMember ? 'member' : 'shop'"
              ></LeadDetailView
              ><!-- type ist vorrübergehend, da damit noch die route definiert wird -->
            </template>

            <LeadDetail
              v-else
              ref="lead_detail"
              :id="leadId"
              :campaign="leadCampaign"
              :leadData="leadData"
              :key="componentKey"
            ></LeadDetail>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogManualLead"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="dialogManualLead = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <v-toolbar-title>{{ $t("createLeadManually") }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <LeadAddManual
              @closeDialog="dialogManualLead = false"
              @newItemAdded="newItemAdded"
            ></LeadAddManual>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import PageHeader from "@/components/ui/PageHeader";
import DataTable from "@/components/ui/DataTable";
import LeadDetailOld from "@/components/lead/old/LeadDetailOld"; // old version
import LeadDetail from "@/components/lead/manage/LeadDetail"; // new version
import LeadDetailView from "@/views/leads/LeadDetailView"; // new version 2024
import LeadAddManual from "@/components/lead/old/LeadAddManual"; // old version

export default {
  name: "Leads",

  components: {
    PageHeader,
    DataTable,
    LeadDetailOld,
    LeadDetail,
    LeadDetailView,
    LeadAddManual,
  },

  data() {
    return {
      pageTitle: this.$t("Persons"),
      pageDescription:
        "Pellentesque in ipsum id orci porta dapibus. Cras ultricies ligula sed magna dictum porta.",
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      leadId: null,
      leadCampaign: null,
      leadData: [],
      data: [], // wrapper for fetched data
      headers: [],
      dialog: false,
      dialogManualLead: false,
      componentKey: 0,
    };
  },

  props: [],

  created: function () {
    this.$store.commit("companyChangeOption/SET_ALLOWED", true);

    /* VUEX store watcher. It fires when current_sid (current studioID) changes */
    this.$store.watch(
      () => store.getters["auth/current_sid"],
      (value) => {
        this.companyID = value;
        this.getData();
      }
    );

    this.getData();
  },

  methods: {
    newItemAdded() {
      this.dialogManualLead = false;
      this.getData();
    },

    async getData() {
      this.fetchinData = true;
      let response = await this.getRequest(`companies/${this.companyID}/leads`); // await data from mixin
      this.fetchinData = false;
      this.data = response.data.data.leads;

      this.headers = [
        /* { text: 'id', value: 'id'}, */
        { text: this.$t("salutation"), value: "gender", width: "50px" },
        { text: this.$t("firstName"), value: "firstName" },
        { text: this.$t("lastName"), value: "lastName" },
        { text: this.$t("email"), value: "email" },
        { text: this.$t("contactType"), value: "provider" }, // campaigns[0].type
        { text: this.$t("campaign"), value: "campaignTitle" },
        {
          text: this.$t("lastActivity"),
          value: "created",
        },

        { text: "", value: "actions", sortable: false, width: "30px" },
      ];
    },

    emittedTableEvent(obj) {
      this.leadId = obj.itemId;
      this.leadCampaign = obj.itemData.campaign;

      //finde the real data in array
      //vorher bin ich über INDEX gegangen, was sich aber als falsch herausgestellt hatte, da der INDEX auf die aktuelle Page der Tabelle bezogen war.
      this.data.forEach((item) => {
        if (item.id === this.leadId) {
          this.leadData = item; // the data of that item
        }
      });

      this.dialog = true;
      this.componentKey += 1; // forces the component (here LeadDetail) to re-render -> https://michaelnthiessen.com/force-re-render/

      /* Direkte Ansicht wurde zunächst ausgeschaltet, da durch die Einführung von PAY-Campaigns
      es so nicht mehr fehlerfrei möglich ist.
      Wir benötigen die Infos über die Kampagne, um einen PAY-Lead anzeigen zu können.
      Die haben wir noch nicht. */

      /* this.$router.push({
        name: "campaigns",
      }); */

      /* //finde the real data in array
      //vorher bin ich über INDEX gegangen, was sich aber als falsch herausgestellt hatte, da der INDEX auf die aktuelle Page der Tabelle bezogen war.
      this.data.forEach((item) => {
        if (item.id === this.leadId) {
          this.leadData = item; // the data of that item
        }
      });

      this.dialog = true;
      this.componentKey += 1; // forces the component (here LeadDetail) to re-render -> https://michaelnthiessen.com/force-re-render/ */
    },
  },

  computed: {
    //
  },

  /* all requests by mixin */
  mixins: [manageApi],
};
</script>
