<template>
  <v-form ref="form" v-model="isFormValid" v-on:submit.prevent="safeData()">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="person_sex"
            :rules="[rules.required]"
            :items="genderOptions"
            item-text="name"
            item-value="value"
            :label="$t('gender')"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
            :label="$t('firstName')"
            v-model="person_firstname"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
            :label="$t('lastName')"
            v-model="person_lastname"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
            :label="$t('email')"
            type="email"
            v-model="person_email"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
            :label="$t('phone')"
            type="number"
            v-model="person_phone"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-select
            v-model="manual_contacttype"
            :rules="[rules.required]"
            :items="contactTypeOptions"
            item-text="name"
            item-value="value"
            :label="$t('contactType')"
          ></v-select>
        </v-col>

        <v-col cols="12">
          <v-checkbox
            v-model="person_client"
            :label="$t('alreadyClient')"
          ></v-checkbox>
        </v-col>

        <v-col cols="12">
          <v-textarea :label="$t('note')" v-model="manual_note"></v-textarea>
        </v-col>

        <v-col cols="12">
          <v-checkbox
            v-model="legalcheck"
            :rules="[rules.required]"
            :label="$t('manualLeadOptIn')"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <div class="d-flex align-end flex-column">
            <v-row>
              <v-btn color="grey" text @click="$emit('closeDialog')">{{
                $t("cancel")
              }}</v-btn>
              <v-btn
                v-if="isFormValid"
                color="green"
                dark
                @click="safeData"
                :loading="fetchinData"
                >{{ $t("safe") }}</v-btn
              >
              <v-btn v-else disabled>{{ $t("safe") }}</v-btn>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="mailerrorDialog" persistent max-width="400">
      <v-card>
        <v-toolbar dark color="red" elevation="0">
          <v-toolbar-title>{{ $t("mailAlreadyExists") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="mailerrorDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="mt-4">{{
          $t("mailAlreadyExistsInfo")
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="mailerrorDialog = false"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import store from "@/store";
import apiRequest from "@/mixins/apiRequest"; // import mixin (for api requests)
export default {
  name: "LeadAddManual",

  components: {
    //
  },

  data() {
    return {
      mailerrorDialog: false,
      isFormValid: false,
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      adminId: store.getters["auth/adminid"],
      componentKey: 0,
      person_sex: "",
      person_firstname: "",
      person_lastname: "",
      person_email: "",
      person_phone: "",
      manual_contacttype: "",
      manual_note: "",
      person_client: false,
      legalcheck: false,
      manual_campaign_id: null,

      genderOptions: [
        {
          name: this.$t("female"),
          value: "female",
        },
        {
          name: this.$t("male"),
          value: "male",
        },
        {
          name: this.$t("diverse"),
          value: "diverse",
        },
      ],

      contactTypeOptions: [
        {
          name: this.$t("walkin"),
          value: "walkin",
        },
        {
          name: this.$t("socialmedia"),
          value: "socialmedia",
        },
        {
          name: this.$t("email"),
          value: "email",
        },
        {
          name: this.$t("custom"),
          value: "custom",
        },
      ],

      rules: {
        required: (value) => !!value || this.$t("required"),
      },
    };
  },

  methods: {
    async safeData() {
      this.fetchinData = true;

      let mailAvailable = [];
      let isAlreadyTaken = false;

      if (this.person_email !== "") {
        mailAvailable = await this.getRequest(
          "check/email/" + this.companyID + "/" + this.person_email
        ); // await data from mixin
        isAlreadyTaken = mailAvailable.data.result.check;
      }

      if (!isAlreadyTaken) {
        let isClient = "0";
        if (this.person_client) {
          isClient = "1";
        }

        var formData = new FormData();
        formData.append("person_sex", this.person_sex);
        formData.append("person_firstname", this.person_firstname);
        formData.append("person_lastname", this.person_lastname);
        formData.append("person_email", this.person_email);
        formData.append("person_phone", this.person_phone);
        formData.append("manual_contacttype", this.manual_contacttype);
        formData.append("manual_note", this.manual_note);
        formData.append("person_client", isClient);
        formData.append("manual_campaign_id", this.manual_campaign_id);

        let response = await this.postRequest(
          "leads/create/" + this.companyID,
          "",
          formData
        );
        this.fetchinData = false;

        if (response.status === 200) {
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText = this.$t("messageUpdated");
          this.$root.snackbarGlobal.errorMessage = "";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;

          this.$refs.form.reset();

          this.$emit("newItemAdded");
        } else {
          this.mailErrorDialog = true;
          this.fetchinData = false;
        }
      } else {
        this.mailerrorDialog = true;
        this.fetchinData = false;
      }
    },
  },

  /* all requests by mixin */
  mixins: [apiRequest],
};
</script>
